html, body {
    height: 100%;
    margin: 0;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure the container takes at least the full viewport height */
  }
  
  main {
    flex: 1; /* Allows the main content area to grow and push the footer to the bottom */
  }
  
  footer {
    margin-top: auto; /* Ensure the footer stays at the bottom */
  }
  